import { observable } from '@trpc/server/observable';
import { t as transformResult, T as TRPCClientError } from '../transformResult-6fb67924.mjs';
import { r as resolveHTTPLinkOptions, h as httpRequest } from '../httpUtils-9b676ab4.mjs';

function httpLink(opts) {
    const resolvedOpts = resolveHTTPLinkOptions(opts);
    return (runtime)=>({ op  })=>observable((observer)=>{
                const { path , input , type  } = op;
                const { promise , cancel  } = httpRequest({
                    ...resolvedOpts,
                    runtime,
                    type,
                    path,
                    input
                });
                promise.then((res)=>{
                    const transformed = transformResult(res.json, runtime);
                    if (!transformed.ok) {
                        observer.error(TRPCClientError.from(transformed.error, {
                            meta: res.meta
                        }));
                        return;
                    }
                    observer.next({
                        context: res.meta,
                        result: transformed.result
                    });
                    observer.complete();
                }).catch((cause)=>observer.error(TRPCClientError.from(cause)));
                return ()=>{
                    cancel();
                };
            });
}

export { httpLink };
