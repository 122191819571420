const isFunction = (fn)=>typeof fn === 'function';
function _bind(fn, thisArg) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    return isFunction(fn.bind) ? fn.bind(thisArg) : fn;
}
function getFetch(customFetchImpl) {
    if (customFetchImpl) {
        return customFetchImpl;
    }
    if (typeof window !== 'undefined' && isFunction(window.fetch)) {
        return _bind(window.fetch, window);
    }
    if (typeof globalThis !== 'undefined' && isFunction(globalThis.fetch)) {
        return _bind(globalThis.fetch, globalThis);
    }
    throw new Error('No fetch implementation found');
}

function getAbortController(customAbortControllerImpl) {
    if (customAbortControllerImpl) {
        return customAbortControllerImpl;
    }
    if (typeof window !== 'undefined' && window.AbortController) {
        return window.AbortController;
    }
    if (typeof globalThis !== 'undefined' && globalThis.AbortController) {
        return globalThis.AbortController;
    }
    return null;
}

function resolveHTTPLinkOptions(opts) {
    const headers = opts.headers || (()=>({}));
    return {
        url: opts.url,
        fetch: getFetch(opts.fetch),
        AbortController: getAbortController(opts.AbortController),
        headers: typeof headers === 'function' ? headers : ()=>headers
    };
}
// https://github.com/trpc/trpc/pull/669
function arrayToDict(array) {
    const dict = {};
    for(let index = 0; index < array.length; index++){
        const element = array[index];
        dict[index] = element;
    }
    return dict;
}
const METHOD = {
    query: 'GET',
    mutation: 'POST'
};
function getInput(opts) {
    return 'input' in opts ? opts.runtime.transformer.serialize(opts.input) : arrayToDict(opts.inputs.map((_input)=>opts.runtime.transformer.serialize(_input)));
}
function getUrl(opts) {
    let url = opts.url + '/' + opts.path;
    const queryParts = [];
    if ('inputs' in opts) {
        queryParts.push('batch=1');
    }
    if (opts.type === 'query') {
        const input = getInput(opts);
        if (input !== undefined) {
            queryParts.push(`input=${encodeURIComponent(JSON.stringify(input))}`);
        }
    }
    if (queryParts.length) {
        url += '?' + queryParts.join('&');
    }
    return url;
}
function getBody(opts) {
    if (opts.type === 'query') {
        return undefined;
    }
    const input = getInput(opts);
    return input !== undefined ? JSON.stringify(input) : undefined;
}
function httpRequest(opts) {
    const { type  } = opts;
    const ac = opts.AbortController ? new opts.AbortController() : null;
    const promise = new Promise((resolve, reject)=>{
        const url = getUrl(opts);
        const body = getBody(opts);
        const meta = {};
        Promise.resolve(opts.headers()).then((headers)=>{
            /* istanbul ignore if -- @preserve */ if (type === 'subscription') {
                throw new Error('Subscriptions should use wsLink');
            }
            return opts.fetch(url, {
                method: METHOD[type],
                signal: ac?.signal,
                body: body,
                headers: {
                    'content-type': 'application/json',
                    ...headers
                }
            });
        }).then((_res)=>{
            meta.response = _res;
            return _res.json();
        }).then((json)=>{
            resolve({
                json: json,
                meta
            });
        }).catch(reject);
    });
    const cancel = ()=>{
        ac?.abort();
    };
    return {
        promise,
        cancel
    };
}

export { getUrl as a, getFetch as g, httpRequest as h, resolveHTTPLinkOptions as r };
