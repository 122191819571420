import { share, observableToPromise } from '@trpc/server/observable';
import { T as TRPCClientError } from './transformResult-6fb67924.mjs';
export { T as TRPCClientError } from './transformResult-6fb67924.mjs';
import { c as createChain } from './splitLink-4c75f7be.mjs';
export { s as splitLink } from './splitLink-4c75f7be.mjs';
import { createFlatProxy, createRecursiveProxy } from '@trpc/server/shared';
export { g as getFetch } from './httpUtils-9b676ab4.mjs';
export { httpBatchLink } from './links/httpBatchLink.mjs';
export { httpLink } from './links/httpLink.mjs';
export { loggerLink } from './links/loggerLink.mjs';
export { createWSClient, wsLink } from './links/wsLink.mjs';

class TRPCUntypedClient {
    $request({ type , input , path , context ={}  }) {
        const chain$ = createChain({
            links: this.links,
            op: {
                id: ++this.requestId,
                type,
                path,
                input,
                context
            }
        });
        return chain$.pipe(share());
    }
    requestAsPromise(opts) {
        const req$ = this.$request(opts);
        const { promise , abort  } = observableToPromise(req$);
        const abortablePromise = new Promise((resolve, reject)=>{
            opts.signal?.addEventListener('abort', abort);
            promise.then((envelope)=>{
                resolve(envelope.result.data);
            }).catch((err)=>{
                reject(TRPCClientError.from(err));
            });
        });
        return abortablePromise;
    }
    query(path, input, opts) {
        return this.requestAsPromise({
            type: 'query',
            path,
            input,
            context: opts?.context,
            signal: opts?.signal
        });
    }
    mutation(path, input, opts) {
        return this.requestAsPromise({
            type: 'mutation',
            path,
            input,
            context: opts?.context,
            signal: opts?.signal
        });
    }
    subscription(path, input, opts) {
        const observable$ = this.$request({
            type: 'subscription',
            path,
            input,
            context: opts?.context
        });
        return observable$.subscribe({
            next (envelope) {
                if (envelope.result.type === 'started') {
                    opts.onStarted?.();
                } else if (envelope.result.type === 'stopped') {
                    opts.onStopped?.();
                } else {
                    opts.onData?.(envelope.result.data);
                }
            },
            error (err) {
                opts.onError?.(err);
            },
            complete () {
                opts.onComplete?.();
            }
        });
    }
    constructor(opts){
        this.requestId = 0;
        function getTransformer() {
            const transformer = opts.transformer;
            if (!transformer) return {
                serialize: (data)=>data,
                deserialize: (data)=>data
            };
            if ('input' in transformer) return {
                serialize: transformer.input.serialize,
                deserialize: transformer.output.deserialize
            };
            return transformer;
        }
        this.runtime = {
            transformer: getTransformer()
        };
        // Initialize the links
        this.links = opts.links.map((link)=>link(this.runtime));
    }
}

function createTRPCUntypedClient(opts) {
    const client = new TRPCUntypedClient(opts);
    return client;
}

/**
 * @deprecated use `createTRPCProxyClient` instead
 */ function createTRPCClient(opts) {
    const client = new TRPCUntypedClient(opts);
    return client;
}

const clientCallTypeMap = {
    query: 'query',
    mutate: 'mutation',
    subscribe: 'subscription'
};
/**
 * @deprecated use `createTRPCProxyClient` instead
 * @internal
 */ function createTRPCClientProxy(client) {
    return createFlatProxy((key)=>{
        if (client.hasOwnProperty(key)) {
            return client[key];
        }
        return createRecursiveProxy(({ path , args  })=>{
            const pathCopy = [
                key,
                ...path
            ];
            const clientCallType = pathCopy.pop();
            const procedureType = clientCallTypeMap[clientCallType];
            const fullPath = pathCopy.join('.');
            return client[procedureType](fullPath, ...args);
        });
    });
}
function createTRPCProxyClient(opts) {
    const client = new TRPCUntypedClient(opts);
    const proxy = createTRPCClientProxy(client);
    return proxy;
}

export { createTRPCClient, createTRPCClientProxy, createTRPCProxyClient, createTRPCUntypedClient };
